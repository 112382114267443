







import { Component, Vue } from 'vue-property-decorator'
import Brand from './components/Brand.vue'

@Component({components: {
    Brand
}})
export default class App extends Vue {
}
