<template>
  <div>
    <p>Not Found</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
